body {
  font-family: mrs-eaves-xl-serif-narrow, serif;
  line-height: 2.4em;
  color: #333;
}

a {
  color: #333333;
}

input, textarea, select, button {
  width: 150px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  font: 1em/1.5em mrs-eaves-xl-serif-narrow, serif;
  background-color: #eee;
  border: solid #ddd;
  border-width: 0 1px 1px 0;
}

input, textarea, select {
  font: 1em/1.5em mrs-eaves-xl-serif-narrow, serif;
  border: 1px #ddd solid;
  padding: 0 10px;
  margin: 0;
  width: 100%;
  background: none;
  border-radius: 5px;
}

textarea {
  resize: vertical;
  width: 100%;
}

input:focus, textarea:focus {
  background: rgba(0,0,0,.1);
  border-radius: 5px;
}

.App-header {
  font-family: proxima-nova, sans-serif;
  background-color: #ffffff;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}


.App-header h1 {
  font-family: lust-script-display, serif;
  line-height: 0.79em;
}

.App-header h1 a {
  text-decoration: none;
}

.App-header nav ol {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.App-header nav li {
  font-weight: 300;
  font-size: calc(13px + 1.2vmin);
  flex-direction: column;
  color: #999999;
  list-style-type: none;
  text-transform: uppercase;
}

.App-header nav li a {
  text-decoration: none;
  color: #999999;
  display: block;
  padding: 1rem 1.5rem;
}

.App-header nav li a:hover {
  color: #666666
}

.App-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-section, .App-footer, .App-dateLocation {
  max-width: 60rem;
  margin: 6rem auto 0 auto;
  padding: 0 2rem;
}

.App-section.no-header {
  margin-top: 0;
}

.App-dateLocation {
  font-family: proxima-nova, sans-serif;
  margin: 5rem auto;
  text-align: center;
  text-transform: uppercase;
}

.App-dateLocation h2 {
  font-weight: normal;
  color: #999999;
  font-size: calc(18px + 2vmin);
}

.App-dateLocation p {
  color: #999999;
  font-size: calc(10px + 2vmin);
  margin: 0;
}

.App-section {
  font-size: calc(13px + 2vmin);
  line-height: calc(1em + 2vmin);
}

.App-circleIcon {
  display: inline-block;
  width: auto;
  background: #333;
  color: #fff;
  font-family: base-mono-wide;
  font-weight: 400;
  width: calc(6px + 5vmin);
  height: calc(6px + 5vmin);
  text-align: center;
  border-radius: 100%;
  font-size: calc(10px + 2vmin);
}

.App-svgIcon {
  display: inline-block;
  width: calc(6px + 5vmin);
  height: calc(6px + 5vmin);
  vertical-align: middle;
}

.App-map {
  max-width: 60rem;
  height: auto;
  width: 100%;
}

.App-map text {
  font-family: base-mono-narrow, monospace;
}

.App-section-innerFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-section-innerFlex > * {
  flex: 0 0 47.5%;
  box-sizing: border-box;
}

.App-section h1 {
  font-family: proxima-nova, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #666;
  font-size: calc(12px + 2vmin);
  padding: 0.5em 0;
  display: inline-block;
  width: 100%;
  border-bottom: 1px #ddd solid;
}

.App-section h2, .App-smalltext {
  font-size: 1.4rem;
}

.App-section h2 + .App-smalltext {
  margin-top: -1.8rem;
}

.App-section .italic {
  font-style: italic;
}

.App-section .bold {
  font-weight: bold;
}

.App-section dt, .App-section dd {
  padding-bottom: 1em;
}

.App-section dt {
  margin-top: 1em;
  font-style: italic;
}

.App-section dd {
  margin: 0;
}

.App-image {
  min-height: 35rem;
  max-width: 60rem;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.App-mast {
  background-image: url("./images/LauraAaron_0382.jpg");
}

.App-footerImage {
  background-image: url("./images/sedona.jpg");
}

.App-footer {
  margin: 5rem auto 1rem auto;
  color: #999;
}

@media only screen and (max-width: 700px) {
  .App-section-innerFlex > * {
    flex-basis: 100%;
  }

  .App-section, .App-footer, .App-dateLocation {
    padding: 0 1.2rem;
  }
}