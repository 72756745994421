.Countdown, .Countdown-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Countdown {
    padding: 0 2rem;
    max-width: 60rem;
    margin: 0 auto;
}

.Countdown-box {
    flex: 0 0 24.5%;
    box-sizing: border-box;
    flex-direction: column;
}

.Countdown-box-number {
    font-family: base-mono-wide;
    font-weight: 400;
    font-size: calc(20px + 6.5vmin);
    line-height: 1.4em;
    color: #333;
}

.Countdown-box-interval {
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: calc(10px + 1vmin);
    color: #888;
}

@media only screen and (max-width: 700px) {
    .Countdown-box {
        flex: 0 0 48.5%;
    }
}